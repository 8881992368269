import Swiper from 'swiper';
import 'swiper/css/swiper.css';
require('../scss/_Styles.scss');

(window as any).appSwiperInit = function appSwiperInit(moduleID: string, autoplay: string, speed: string, effectDefaults: any, fallback: any) {
  var configured = {
    autoplay: (autoplay === 'True'),
    speed: speed
  };
  var merged = Object.assign(fallback, effectDefaults, configured);
  var mySwiper = new Swiper (`.swiper-${moduleID}`, merged);
}

var galleryThumbs = new Swiper(".gallery-thumbs", {
  spaceBetween: 0,
  slidesPerView: 3,
  centeredSlides: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  loop: true,
  loopedSlides: 3,
  slideToClickedSlide: true,
});

var galleryTop = new Swiper(".gallery", {
  autoplay: true,
  spaceBetween: 0,
  loop: true,
  loopedSlides: 3,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
});

(galleryTop as any).controller.control = galleryThumbs;
(galleryThumbs as any).controller.control = galleryTop;